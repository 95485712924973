import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TuneIcon from '@mui/icons-material/Tune';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import {
  ApartmentIcon,
  CloseIcon,
  ClientsIcon,
  DashboardIcon,
  GroupIcon,
  LogoutIcon,
  MenuIcon,
  ProfileIcon,
  ProjectsIcon,
  StorageIcon,
  NotificationsIcon,
  DataSaverIcon,
} from 'assets/icons';
import logo from 'assets/images/logo.webp';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useCurrentUser from 'hooks/useCurrentUser';
import useIsMobile from 'hooks/useIsMobile';
import { signOutUser } from 'redux/slices/user.slice';

export const drawerWidth = 310;
export const drawerWidthMD = 270;

const SideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(6vh, 45px)',
  color: theme.palette.text.primary,
  backgroundColor: '#fff',
  paddingLeft: '38px',
  paddingRight: '38px',
  borderRadius: '37px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    height: '50px',
    '& span': {
      fontSize: '18px !important',
      color: 'inherit',
      fontWeight: 400,
    },
  },
  '& span': {
    fontSize: '16px !important',
    color: 'inherit',
    fontWeight: 400,
  },
  '&:hover': {
    color: '#26859C',
    '& span': {
      color: '#26859C',
    },
  },
}));

const CurrentSideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(6vh, 45px)',
  color: '#fff',
  backgroundColor: theme.palette.text.primary,
  paddingLeft: '38px',
  paddingRight: '38px',
  borderRadius: '37px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    height: '50px',
    '& span': {
      fontSize: '18px !important',
      color: 'inherit',
      fontWeight: 600,
    },
  },
  '& span': {
    fontSize: '16px !important',
    color: 'inherit',
    fontWeight: 600,
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: theme.palette.text.primary,
    '& span': {
      color: '#fff',
    },
  },
}));
interface SideBarItemInterface {
  text: string;
  Icon: React.ReactNode;
  isSelected: boolean;
  path:
    | '/'
    | '/project'
    | '/clients'
    | '/profile'
    | '/users'
    | '/properties'
    | '/db-reader'
    | '/analysis'
    | '/questions'
    | '/information'
    | '/alerts'
    | '/looker';
}

interface SideBarButtonInterface extends SideBarItemInterface {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignOutButton: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(signOutUser());
    navigate('/sign-in');
  };
  return (
    <ListItem sx={{ px: '38px' }}>
      <SideBarItemButton onClick={handleClick} sx={{ px: 4 }}>
        <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </SideBarItemButton>
    </ListItem>
  );
};

const SideBarButton: React.FC<SideBarButtonInterface> = ({ text, Icon, isSelected, path, setMobileOpen }) => {
  const { isMobile } = useIsMobile();
  const handleClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };
  return (
    <Link to={path} style={{ textDecoration: 'none' }} onClick={handleClick}>
      <ListItem sx={{ px: '38px', py: '2px' }}>
        {isSelected ? (
          <CurrentSideBarItemButton sx={{ px: 4 }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </CurrentSideBarItemButton>
        ) : (
          <SideBarItemButton sx={{ px: 4 }}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
            <ListItemText primary={text} />
          </SideBarItemButton>
        )}
      </ListItem>
    </Link>
  );
};

const SideBar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { path } = useCurrentRoute();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const currentUser = useCurrentUser();
  const firstSection: SideBarItemInterface[] = [
    {
      text: 'Inicio',
      Icon: <DashboardIcon />,
      path: '/',
      isSelected: path === '/',
    },
    {
      text: 'Proyecto',
      Icon: <ProjectsIcon />,
      path: '/project',
      isSelected: path.includes('/project'),
    },
    {
      text: 'Clientes',
      path: '/clients',
      Icon: <ClientsIcon />,
      isSelected: path.includes('/clients'),
    },
    {
      text: 'Análisis (βeta)',
      Icon: <AutoGraphIcon />,
      path: '/analysis',
      isSelected: path.includes('/analysis'),
    },
    {
      text: 'Preguntas',
      Icon: <QuestionMarkIcon />,
      path: '/questions',
      isSelected: path.includes('/questions'),
    },
    {
      text: 'Alertas',
      Icon: <NotificationsIcon />,
      path: '/alerts',
      isSelected: path.includes('/alerts'),
    },
  ];

  const projectMasterUser: SideBarItemInterface[] = [
    {
      text: 'Reporte',
      Icon: <DataSaverIcon />,
      path: '/looker',
      isSelected: path.includes('/looker'),
    },
  ];

  const secondSection: SideBarItemInterface[] = [
    {
      text: 'Mi perfil',
      Icon: <ProfileIcon />,
      isSelected: path.includes('/profile'),
      path: '/profile',
    },
  ];

  const adminSection: SideBarItemInterface[] = [
    {
      text: 'Usuarios',
      Icon: <GroupIcon />,
      path: '/users',
      isSelected: path.includes('/users'),
    },
    {
      text: 'Propiedades',
      Icon: <ApartmentIcon />,
      path: '/properties',
      isSelected: path.includes('/properties'),
    },
    {
      text: 'DB reader',
      Icon: <StorageIcon />,
      path: '/db-reader',
      isSelected: path.includes('/db-reader'),
    },
    {
      text: 'Información',
      Icon: <TuneIcon />,
      path: '/information',
      isSelected: path.includes('/information'),
    },
  ];

  const drawer = (
    <div>
      <Toolbar
        sx={{
          py: { xs: '16px', xl: '24px' },
          px: '46px !important',
          minHeight: { xs: 'min(80px, 12vh)', xl: '100px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <img src={logo} alt="logo" style={{ width: '80%' }} />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            display: { xs: 'block', lg: 'none' },
            ml: 4,
            mb: 4,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ pb: { xs: '5px', xl: '10px' }, pt: 0 }}>
        {firstSection.map((section) => (
          <SideBarButton
            Icon={section.Icon}
            text={section.text}
            key={section.text}
            isSelected={section.isSelected}
            path={section.path}
            setMobileOpen={setMobileOpen}
          />
        ))}
        {(!currentUser?.subProjectId || currentUser.isAdmin) &&
          projectMasterUser.map((section) => (
            <SideBarButton
              Icon={section.Icon}
              text={section.text}
              key={section.text}
              isSelected={section.isSelected}
              path={section.path}
              setMobileOpen={setMobileOpen}
            />
          ))}
      </List>
      <Divider sx={{ width: '75%', mx: 'auto' }} />
      <List>
        {secondSection.map((section) => (
          <SideBarButton
            Icon={section.Icon}
            text={section.text}
            key={section.text}
            isSelected={section.isSelected}
            path={section.path}
            setMobileOpen={setMobileOpen}
          />
        ))}
        {currentUser?.isAdmin &&
          adminSection.map((section) => (
            <SideBarButton
              Icon={section.Icon}
              text={section.text}
              key={section.text}
              isSelected={section.isSelected}
              path={section.path}
              setMobileOpen={setMobileOpen}
            />
          ))}
        <SignOutButton />
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { xs: 'block', xl: 'none' },
          position: 'fixed',
          top: '20px',
          left: `25px`,
          zIndex: '100',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { xs: drawerWidthMD, xl: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: { md: drawerWidthMD, xl: drawerWidth },
              border: 0,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;

import { BaseModel } from './baseModel';
import { Debt } from './debt';
import { Message } from './message';
import { Project } from './project';

export const CLIENT_STATUSES = [
  'no contactado',
  'contactado',
  'reserva',
  'promesa',
  'escritura',
  'descartado',
  '',
] as const;

export type ClientProfileFields = {
  attributeName: string;
  attributeType: string;
  manualValue?: string;
  aggregationFunction?: string;
  calculation?: Array<string>; // Steps like an ecuation: [attributeName, operator, attributeName, operator,...]
};

export interface ClientDocument {
  id: number;
  originalName: string;
  url: string;
  AIName: string;
  isMainBuyer: boolean;
}

export interface ProfileRecord {
  id: number;
  attributeName: string;
  stringValue?: string;
  numberValue?: number;
  booleanValue?: boolean;
  dateValue?: Date;
  clientDocumentId?: number;
}

export interface ClientProfile {
  id: number;
  isMainBuyer: boolean;
  requiredFields: string[];
  fields: Array<ClientProfileFields | null>;
  records: ProfileRecord[];
  frontRecords: Record<string, Array<string | number> | null>;
}

export type ClientStatus = (typeof CLIENT_STATUSES)[number];
export interface Client extends BaseModel {
  email: string | null;
  rut: string | null;
  phone: number;
  firstName: string | null;
  lastName: string | null;
  savingsAmount: number | null;
  vehicleValue: number | null;
  housingStatus: string | null;
  educationLevel: string | null;
  degree: string | null;
  notes: string | null;
  adminNotes: string | null;
  workContract: string | null;
  motherLastName: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  nationality: string | null;
  maritalStatus: string | null;
  messages: Message[] | null;
  salary: number;
  salarySupplement: number;
  score: number;
  debts: Debt[] | null | undefined;
  projectId: number;
  project: Project;
  lastMessageDate: Date | null;
  quote: string | null;
  messagesCount: number;
  clientMessagesCount: number;
  imagesSent: number;
  followUpsSent: number;
  templatesSent: number;
  keepAliveMessagesSent: number;
  floidRequestsCount?: number;
  floidRequestsDetails?: string;
  isTestClient: boolean;
  disabledAutomaticResponses: boolean;
  source: string;
  status?: ClientStatus | null;
  didBookVisit: boolean;
  didVisit: boolean;
  userScore: number | null;
  subProjectId: number | null;
  subProject: Project | null;
  financialScore: number | null;
  interestScore: number | null;
  manualScore: number | null;
  prospectMessage: string | null;
  buyerType: string | null;
  aiSafetyStatus: string | null;
  charge: boolean | null;
  isSalesAgent: boolean;
  lastMessageRole: string | null;
  isDuplicate: boolean;
  chatType: string | null;
  crmLastUpdatedAt: Date | null;
  dHunterIds: number[] | null;
  dHunterLastUpdatedAt: Date | null;
  hubspotDealId: number | null;
  hubspotId: string | null;
  planOkId: string | null;
  planOkClientId: string | null;
  planOkLastUpdatedAt: Date | null;
  subProjectHistory: string | null;
  totalSalary: number | null;
  webhookBodyAtCreation: string | null;
  employmentContractType: string | null;
  employmentContractStartDate: string | null;
  scope: string | null;
  topics: string[] | null;
  endReason: string | null;
  aiScore: number | null;
  scoreHistory: string | null;
  selectedPropertyType: string | null;
  profiles: ClientProfile[];
  documents: ClientDocument[];
  usersMessagesCount: number;
}

export interface EditableClientAttributes {
  notes?: string;
  status?: ClientStatus | null;
  didBookVisit?: boolean;
  didVisit?: boolean;
  userScore?: number;
  disabledAutomaticResponses?: boolean;
}
export interface EditableAdminClientAttributes extends EditableClientAttributes {
  adminNotes?: string;
  rut?: string;
  email?: string;
  phone?: number;
  firstName?: string;
  lastName?: string;
  salary?: number;
  salarySupplement?: number;
  savingsAmount?: number;
  vehicleValue?: number;
  housingStatus?: string;
  educationLevel?: string;
  degree?: string;
  workContract?: string;
  motherLastName?: string;
  gender?: string;
  dateOfBirth?: string;
  nationality?: string;
  maritalStatus?: string;
  source?: string;
  manualScore?: number;
  disabledAutomaticResponses?: boolean;
  isTestClient?: boolean;
  prospectMessage?: string;
  buyerType?: string;
  subProjectId?: number;
  isSalesAgent?: boolean;
  employmentContractType?: string;
  employmentContractStartDate?: string;
  scope?: string;
  endReason?: string;
}

import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import type { ClientProfile, ClientDocument } from '../../../services/types/client';
import ProfileRecord from './profileRecord';

interface ProfileModalProps {
  profile: ClientProfile;
  documents: ClientDocument[];
  isAdmin: boolean;
  onClose: () => void;
  refetch: () => void;
}

const MORE_THAN_ONE = ['liquidSalary', 'grossSalary', 'feeReciptInfo'];
const TRADUCTION: Record<string, string> = {
  liquidSalary: 'Salario Líquido',
  grossSalary: 'Salario Bruto',
  feeReciptInfo: 'Recibo Honorarios',
};

const ProfileModal: React.FC<ProfileModalProps> = ({ profile, documents, isAdmin, onClose, refetch }) => {
  const filteredDocuments = documents.filter((doc) => doc.isMainBuyer === profile.isMainBuyer);
  const [isEditingRecords, setIsEditingRecords] = React.useState(false);

  const handleFinishEditing = () => {
    setIsEditingRecords(!isEditingRecords);
    if (isEditingRecords) {
      refetch();
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        overflowY: 'auto',
        borderRadius: 5,
        zIndex: 10,
      }}
    >
      <Typography variant="h3" sx={{ m: 2, fontSize: 30 }}>
        {profile.isMainBuyer ? 'Ficha Principal' : 'Ficha Complementaria'}
      </Typography>
      <Button sx={{ m: 2 }} variant="contained" color="primary" onClick={onClose}>
        Cerrar
      </Button>
      {isAdmin && (
        <Button
          sx={{ m: 2 }}
          variant="contained"
          color="primary"
          onClick={() => {
            handleFinishEditing();
          }}
        >
          {isEditingRecords ? 'Listo' : 'Editar'}
        </Button>
      )}

      <Box sx={{ display: 'flex', ml: 2 }}>
        {/* Left Panel */}
        <Box sx={{ flex: 4, borderRight: '1px solid #ddd', pr: 2 }}>
          <Box sx={{ mb: 2, border: '1px solid #ddd', padding: 1 }}>
            <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
              Información Personal
            </Typography>
            <ProfileRecord
              recordName="Rut"
              recordValue={(profile.frontRecords.rut?.[0] as string) || '-'}
              recordId={profile.frontRecords.rut?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Nombre"
              recordValue={(profile.frontRecords.firstName?.[0] as string) || '-'}
              recordId={profile.frontRecords.firstName?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Apellido"
              recordValue={(profile.frontRecords.lastName?.[0] as string) || '-'}
              recordId={profile.frontRecords.lastName?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Nacimiento"
              recordValue={(profile.frontRecords.dateOfBirth?.[0] as string) || '-'}
              recordId={profile.frontRecords.dateOfBirth?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Nacionalidad"
              recordValue={(profile.frontRecords.nationality?.[0] as string) || '-'}
              recordId={profile.frontRecords.nationality?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
          </Box>
          <Box sx={{ mb: 2, border: '1px solid #ddd', padding: 2 }}>
            <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
              Resumen Ingresos
            </Typography>
            <ProfileRecord
              recordName="Antigüedad Laboral"
              recordValue={(profile.frontRecords.jobSeniority?.[0] as string) || '-'}
              recordId={profile.frontRecords.jobSeniority?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Rut Empleador"
              recordValue={(profile.frontRecords.employerRut?.[0] as string) || '-'}
              recordId={profile.frontRecords.employerRut?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Tipo de Contrato"
              recordValue={(profile.frontRecords.employmentContractType?.[0] as string) || '-'}
              recordId={profile.frontRecords.employmentContractType?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Profesión"
              recordValue={(profile.frontRecords.profession?.[0] as string) || '-'}
              recordId={profile.frontRecords.profession?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            {/* Iterate over the profile, find 'liquidSalary', 'grossSalary' and 'feeReciptInfo' and map the value */}
            {Object.entries(profile.frontRecords).map(([key, value], index) => {
              const splittedKey = key.split('_');
              if (MORE_THAN_ONE.includes(splittedKey[0])) {
                return (
                  <ProfileRecord
                    recordName={`${TRADUCTION[splittedKey[0]]}_${splittedKey[1] || ''}`}
                    realName={key}
                    recordValue={(value?.[0] as string) || '-'}
                    recordId={value?.[1] as number | undefined}
                    canEditRecords={isEditingRecords && isAdmin}
                    key={`${key}-${index}`} // eslint-disable-line react/no-array-index-key
                  />
                );
              }
              return null;
            })}
          </Box>
          <Box sx={{ mb: 2, border: '1px solid #ddd', padding: 2 }}>
            <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
              Ingresos (Otros)
            </Typography>
            <ProfileRecord
              recordName="Línea 110"
              recordValue={(profile.frontRecords.avgLiquidIncome?.[0] as string) || '-'}
              recordId={profile.frontRecords.avgLiquidIncome?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Valor Arriendo Propiedades"
              recordValue={`$${profile.frontRecords.leaseValue?.[0] || '0'}`}
              recordId={profile.frontRecords.leaseValue?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Pago Promedio de Dividendos"
              recordValue={`$${profile.frontRecords.avgLeasePayment?.[0] || '0'}`}
              recordId={profile.frontRecords.avgLeasePayment?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
          </Box>
          <Box sx={{ mb: 2, border: '1px solid #ddd', padding: 2 }}>
            <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
              Activos
            </Typography>
            <ProfileRecord
              recordName="Cantidad de Propiedades"
              recordValue={`${profile.frontRecords.numberOfProperties?.[0] || '-'}`}
              recordId={profile.frontRecords.numberOfProperties?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Valor Total Propiedades"
              recordValue={`$${profile.frontRecords.propertiesValuesSum?.[0] || '0'}`}
              recordId={profile.frontRecords.propertiesValuesSum?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Cantidad de Vehículos"
              recordValue={`$${profile.frontRecords.vehicleValue?.[0] || '0'}`}
              recordId={profile.frontRecords.vehicleValue?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Cuenta Ahorros"
              recordValue={`$${profile.frontRecords.savingsAccountAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.savingsAccountAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Depósito a Plazo"
              recordValue={`$${profile.frontRecords.fixedDepositAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.fixedDepositAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Inversión en Fondos Mutuos"
              recordValue={`$${profile.frontRecords.mutualFundsAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.mutualFundsAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
          </Box>
          <Box sx={{ mb: 2, border: '1px solid #ddd', padding: 2 }}>
            <Typography variant="h2" sx={{ mb: 1, textAlign: 'center' }}>
              Pasivos
            </Typography>
            <Typography variant="h2" sx={{ mb: 1 }}>
              Deudas CMF:
            </Typography>
            <ProfileRecord
              recordName="Deuda Total"
              recordValue={`$${profile.frontRecords.liabilitiesAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.liabilitiesAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Deuda Hipotecaria"
              recordValue={`$${profile.frontRecords.mortgageliabilitiesAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.mortgageliabilitiesAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Deudas de Consumo"
              recordValue={`$${profile.frontRecords.consumerLiabilitiesAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.consumerLiabilitiesAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <Typography variant="h2" sx={{ mb: 1 }}>
              Otros:
            </Typography>
            <ProfileRecord
              recordName="Deuda Hipotecaria"
              recordValue={`$${profile.frontRecords.mortgageFeeAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.mortgageFeeAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Número de Cuota"
              recordValue={`${profile.frontRecords.mortgageFeeNumber?.[0] || '-'}`}
              recordId={profile.frontRecords.mortgageFeeNumber?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Cantidad Total de Cuotas"
              recordValue={`${profile.frontRecords.mortgageTotalFees?.[0] || '-'}`}
              recordId={profile.frontRecords.mortgageTotalFees?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Deudas de Consumo"
              recordValue={`$${profile.frontRecords.consumptionFeeAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.consumptionFeeAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Número de Cuota"
              recordValue={`${profile.frontRecords.consumptionFeeNumber?.[0] || '-'}`}
              recordId={profile.frontRecords.consumptionFeeNumber?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Cantidad Total de Cuotas"
              recordValue={`${profile.frontRecords.consumptionTotalFees?.[0] || '-'}`}
              recordId={profile.frontRecords.consumptionTotalFees?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Deuda de Crédito"
              recordValue={`$${profile.frontRecords.creditFeeAmount?.[0] || '0'}`}
              recordId={profile.frontRecords.creditFeeAmount?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Número de Cuota"
              recordValue={`${profile.frontRecords.creditFeeNumber?.[0] || '-'}`}
              recordId={profile.frontRecords.creditFeeNumber?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
            <ProfileRecord
              recordName="Cantidad Total de Cuotas"
              recordValue={`${profile.frontRecords.creditTotalFees?.[0] || '-'}`}
              recordId={profile.frontRecords.creditTotalFees?.[1] as number | undefined}
              canEditRecords={isEditingRecords && isAdmin}
            />
          </Box>
        </Box>

        {/* Right Panel */}
        <Box sx={{ flex: 3, pl: 2 }}>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Documentos Asociados
          </Typography>
          {filteredDocuments.length > 0 ? (
            <ul>
              {filteredDocuments.map((doc) => (
                <li key={doc.id}>
                  <a
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#007BFF', textDecoration: 'none' }}
                  >
                    {doc.AIName ?? doc.originalName}
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <Typography>No hay documentos asociados.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileModal;

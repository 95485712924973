import { ProfileRecord } from '../types/client';
import baseApi from './baseApi';

const underwritingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    patchProfileRecord: build.mutation({
      query: (body: { value: string; recordId: number; name: string | undefined }) => ({
        url: `/admin/underwriting/records/${body.recordId}`,
        method: 'PATCH',
        body: {
          value: body.value,
          name: body.name,
        },
      }),
      transformResponse: (response: ProfileRecord): ProfileRecord => response,
    }),
  }),
  overrideExisting: false,
});

const { usePatchProfileRecordMutation } = underwritingApi;
export default usePatchProfileRecordMutation;

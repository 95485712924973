import React, { useState } from 'react';

import { Box, Button, TextField } from '@mui/material';

import usePatchProfileRecordMutation from '../../../services/api/underwriting.api';

interface ProfileRecordProps {
  recordName: string;
  realName?: string;
  recordValue?: string;
  recordId?: number;
  canEditRecords: boolean;
}

const ProfileRecord: React.FC<ProfileRecordProps> = ({
  recordName,
  realName,
  recordValue,
  recordId,
  canEditRecords,
}) => {
  const [valueDraft, setValueDraft] = useState(recordValue);
  const [nameDraft, setNameDraft] = useState(realName);
  const [patchProfileRecord] = usePatchProfileRecordMutation();

  const handleRecordEdit = (idToEdit: number) => {
    if (idToEdit && valueDraft) {
      patchProfileRecord({ value: valueDraft, recordId: idToEdit, name: nameDraft })
        .then((res) => {
          if ('data' in res) {
            alert(`Correctly actualized record ${res.data.id}`); // eslint-disable-line no-alert
          } else {
            alert(`Failed to patch profile record: ${JSON.stringify(res.error)}`); // eslint-disable-line no-alert
          }
        })
        // eslint-disable-next-line no-console
        .catch((e) => console.error(e));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {/* If it's admin permit to edit the value */}
      {canEditRecords && nameDraft ? (
        <TextField
          type="text"
          value={nameDraft}
          onChange={(e) => setNameDraft(e.target.value)}
          sx={{ width: '100%', '& input': { padding: '5px 10px' }, marginRight: 10 }}
        />
      ) : (
        <p style={{ marginRight: 10 }}>{recordName}:</p>
      )}
      {canEditRecords ? (
        <TextField
          type="text"
          value={valueDraft}
          onChange={(e) => setValueDraft(e.target.value)}
          // style={{ width: '100%', '& input': { padding: '10px 15px' } }}
          sx={{ width: '100%', '& input': { padding: '5px 10px' } }}
        />
      ) : (
        <p>{recordValue}</p>
      )}
      {recordId && canEditRecords && (
        <Button variant="text" onClick={() => handleRecordEdit(recordId)}>
          Editar
        </Button>
      )}
    </Box>
  );
};

ProfileRecord.defaultProps = {
  realName: undefined,
  recordValue: '-',
  recordId: undefined,
};

export default ProfileRecord;

import { Client, EditableAdminClientAttributes, EditableClientAttributes } from 'services/types/client';
import { Lead } from 'services/types/lead';

import { SendMessage, Message, TemplateResponse, SendTemplate } from '../types/message';
import baseApi from './baseApi';

const whatsAppApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    postMessage: build.mutation({
      query: (body: SendMessage) => ({
        url: `/clients/${body.clientId}/messages`,
        method: 'POST',
        body: {
          text: body.text,
        },
      }),
      transformResponse: (response: Message) => response,
    }),
    postTemplateMessage: build.mutation({
      query: (body: SendTemplate) => ({
        url: `/clients/${body.clientId}/template-messages`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: Message) => response,
    }),
    getClient: build.mutation({
      query: (id: number) => ({
        url: `/clients/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Client): Client => response,
    }),
    updateEditableAttributes: build.mutation({
      query: (props: { id: number; body: EditableClientAttributes }) => ({
        url: `/clients/${props.id}/editable-attributes`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Client): Client => response,
    }),
    updateAdminEditableAttributes: build.mutation({
      query: (props: { id: number; body: EditableAdminClientAttributes }) => ({
        url: `/clients/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Client): Client => response,
    }),
    getClients: build.query({
      query: (body: {
        offset: number;
        limit: number;
        filterModel: string;
        sortModel: string;
        searchTerm: string;
        projectId: string;
      }) => ({
        url: `/clients?offset=${body.offset}&limit=${body.limit}&filterModel=${body.filterModel}&sortModel=${body.sortModel}&searchTerm=${body.searchTerm}&projectId=${body.projectId}`,
        method: 'GET',
      }),
      transformResponse: (response: {
        clients: Client[];
        total: number;
        totalPages: number;
      }): { clients: Client[]; total: number; totalPages: number } => response,
    }),
    getTemplates: build.query({
      query: (body: { clientId: number }) => ({
        url: `/clients/${body.clientId}/templates`,
        method: 'GET',
      }),
      transformResponse: (response: TemplateResponse[]) => response,
    }),
    adminGetClientCost: build.mutation({
      query: (id: number) => ({
        url: `/admin/clients/${id}/cost`,
        method: 'GET',
      }),
      transformResponse: (response: {
        totalMessagesCost: number;
        totalFloidRequestsCost: number;
        totalCost: number;
      }): number => response.totalCost,
    }),
    getClientLeads: build.query({
      query: (id: number) => ({
        url: `/clients/${id}/leads`,
        method: 'GET',
      }),
      transformResponse: (response: { leads: Lead[] }) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetClientMutation,
  usePostMessageMutation,
  useGetClientsQuery,
  usePostTemplateMessageMutation,
  useGetTemplatesQuery,
  useUpdateEditableAttributesMutation,
  useUpdateAdminEditableAttributesMutation,
  useAdminGetClientCostMutation,
  useGetClientLeadsQuery,
} = whatsAppApi;

import * as React from 'react';

import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { BarChart } from '@mui/x-charts/BarChart';

type MetricsBarChartProps = {
  type: string;
  filteredData: Record<string, number | null>;
  secondSeries?: Record<string, number | null>;
  labels?: string[];
};

export const MetricsBarChart: React.FC<MetricsBarChartProps> = ({ type, filteredData, secondSeries, labels }) => {
  const series = [
    {
      data: Object.values(filteredData).filter((value) => value !== null),
      stack: 'stack',
      label: labels && labels.length > 0 ? labels[0] : undefined,
    },
  ];
  if (secondSeries) {
    series.push({
      data: Object.values(secondSeries).filter((value) => value !== null),
      stack: 'stack',
      label: labels && labels.length > 1 ? labels[1] : undefined,
    });
  }
  return (
    <Card>
      <CardContent>
        {filteredData ? (
          <Typography component="h2" variant="h6" color="text.primary" align="center">
            {type}
            <BarChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: Object.keys(filteredData).map((key) => key.padStart(2, '0')) || ['No hay datos'],
                  scaleType: 'band',
                },
              ]}
              series={series}
              height={300}
              colors={['#0070c0', '#f02b2c']}
            />
          </Typography>
        ) : (
          <Typography component="p" variant="h4">
            No hay datos para mostrar
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export const MetricsBarChartStr: React.FC<MetricsBarChartProps> = ({ type, filteredData, secondSeries, labels }) => {
  const series = [
    {
      data: Object.values(filteredData).filter((value) => value !== null),
      stack: 'stack',
      label: labels && labels.length > 0 ? labels[0] : undefined,
    },
  ];
  if (secondSeries) {
    series.push({
      data: Object.values(secondSeries).filter((value) => value !== null),
      stack: 'stack',
      label: labels && labels.length > 1 ? labels[1] : undefined,
    });
  }
  return (
    <Card>
      <CardContent>
        {filteredData ? (
          <Typography component="h2" variant="h6" color="text.primary" align="center">
            {type}
            <BarChart
              margin={{ bottom: 120, right: 80 }}
              xAxis={[
                {
                  id: 'barCategories',
                  data: Object.keys(filteredData) || ['No hay datos'],
                  scaleType: 'band',
                  labelStyle: {
                    fontSize: 14,
                  },
                  tickLabelStyle: {
                    angle: 45,
                    textAnchor: 'start',
                    fontSize: 12,
                  },
                },
              ]}
              series={series}
              height={400}
              colors={['#0070c0', '#f02b2c']}
            />
          </Typography>
        ) : (
          <Typography component="p" variant="h4">
            No hay datos para mostrar
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

MetricsBarChart.defaultProps = {
  secondSeries: undefined,
  labels: undefined,
};

MetricsBarChartStr.defaultProps = {
  secondSeries: undefined,
  labels: undefined,
};

export default MetricsBarChart;

import React, { FC, useEffect } from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { useGetProjectsQuery } from 'services/api/projects.api';
import type { Project } from 'services/types/project';

interface ProjectSelectorProps {
  onSelect: (project: Project | null) => void;
  projectId: number | null | undefined;
  onlyMasterProjects?: boolean;
  refetchFlag?: boolean;
}

const ProjectSelector: FC<ProjectSelectorProps> = ({
  onSelect,
  projectId,
  onlyMasterProjects,
  refetchFlag,
}: ProjectSelectorProps) => {
  const { data: projects, refetch } = useGetProjectsQuery(null);
  useEffect(() => {
    refetch().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, [refetchFlag]);

  useEffect(() => {
    if (projects && projectId) {
      onSelect(projects.find((x) => x.id === projectId) ?? null);
    }
  }, [projects]);
  let label = 'Inmobiliaria';
  if (!onlyMasterProjects) {
    label += '/ Proyecto';
  }
  return (
    <FormControl sx={{ minWidth: 250, paddingRight: '20px', mt: 1 }}>
      <InputLabel id="project-select-label">{label}</InputLabel>
      <Select
        labelId="project-select-label"
        id="project-select"
        value={projectId?.toString() ?? ''}
        label={label}
        onChange={(event) => onSelect(projects?.find((x) => x.id === Number(event.target.value)) ?? null)}
      >
        {projects
          ?.filter((p) => !onlyMasterProjects || p.masterProjectId === null)
          .map((projectElement) => (
            <MenuItem key={projectElement.id} value={projectElement.id}>
              <Typography variant="h3" color={projectElement.masterProjectId ? 'grey' : 'black'}>
                {projectElement.masterProjectId ? `- ${projectElement.name}` : projectElement.name}
                <span style={{ fontSize: '0.5em', color: '#aaa', marginLeft: '5px' }}>({projectElement.id})</span>
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

ProjectSelector.defaultProps = {
  onlyMasterProjects: false,
  refetchFlag: false,
};

export default ProjectSelector;
